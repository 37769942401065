export class countriesModel
{     
    constructor(code?,name?, region?, callingCode?) {
        this.code=code;
        this.name=name;
        this.region = region;
        this.callingCode = callingCode;
       
    }
    code: string;
    name : string;  
    region: string; 

    /** added by sandeep on July 14, 2023 */
    callingCode: number
}