export class managemanualModel{
    ID: number;
    Manual_Name : string ;  
    Modified_By:string;  
    Modified_Date:Date; 
    File_Path:string;
    Manual_Date:Date;
    
    /** added by sandeep on Jun 1, 2023 */
    language: string;
}